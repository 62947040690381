@use "sass:map";
@use '@angular/material' as mat;

@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';
@import '@angular/material/theming';

@include mat.core();

$dcy-primary-color-palette: (
	50: var(--hub-primary-color-50),
	100: var(--hub-primary-color-100),
	200: var(--hub-primary-color-200),
	300: var(--hub-primary-color-300),
	400: var(--hub-primary-color-400),
	500: var(--hub-primary-color-500),
	600: var(--hub-primary-color-600),
	700: var(--hub-primary-color-700),
	800: var(--hub-primary-color-800),
	900: var(--hub-primary-color-900),
	A100: var(--hub-primary-color-A100),
	A200: var(--hub-primary-color-A200),
	A400: var(--hub-primary-color-A400),
	A700: var(--hub-primary-color-A700),
	contrast: (
		50: var(--hub-contrast-primary-color-50),
		100: var(--hub-contrast-primary-color-100),
		200: var(--hub-contrast-primary-color-200),
		300: var(--hub-contrast-primary-color-300),
		400: var(--hub-contrast-primary-color-400),
		500: var(--hub-contrast-primary-color-500),
		600: var(--hub-contrast-primary-color-600),
		700: var(--hub-contrast-primary-color-700),
		800: var(--hub-contrast-primary-color-800),
		900: var(--hub-contrast-primary-color-900),
		A100: var(--hub-contrast-primary-color-A100),
		A200: var(--hub-contrast-primary-color-A200),
		A400: var(--hub-contrast-primary-color-A400),
		A700: var(--hub-contrast-primary-color-A700),
	)
);

$dcy-accent-color-palette: (
	50: var(--hub-accent-color-50),
	100: var(--hub-accent-color-100),
	200: var(--hub-accent-color-200),
	300: var(--hub-accent-color-300),
	400: var(--hub-accent-color-400),
	500: var(--hub-accent-color-500),
	600: var(--hub-accent-color-600),
	700: var(--hub-accent-color-700),
	800: var(--hub-accent-color-800),
	900: var(--hub-accent-color-900),
	A100: var(--hub-accent-color-A100),
	A200: var(--hub-accent-color-A200),
	A400: var(--hub-accent-color-A400),
	A700: var(--hub-accent-color-A700),
	contrast: (
		50: var(--hub-contrast-accent-color-50),
		100: var(--hub-contrast-accent-color-100),
		200: var(--hub-contrast-accent-color-200),
		300: var(--hub-contrast-accent-color-300),
		400: var(--hub-contrast-accent-color-400),
		500: var(--hub-contrast-accent-color-500),
		600: var(--hub-contrast-accent-color-600),
		700: var(--hub-contrast-accent-color-700),
		800: var(--hub-contrast-accent-color-800),
		900: var(--hub-contrast-accent-color-900),
		A100: var(--hub-contrast-accent-color-A100),
		A200: var(--hub-contrast-accent-color-A200),
		A400: var(--hub-contrast-accent-color-A400),
		A700: var(--hub-contrast-accent-color-A700),
	)
);

$primaryPalette: mat.define-palette($dcy-primary-color-palette);
$accentPalette: mat.define-palette($dcy-accent-color-palette);

$theme: mat.define-light-theme(
	(
		color: (
			primary: $primaryPalette,
      		accent: $accentPalette,
    	)
	)
);

@include mat.all-component-themes($theme);
$primary: map.get($theme, primary);
$accent: map.get($theme, accent);
$warn: map.get($theme, warn);
